<template>
    <div class="container-fluid">
        <div class="row">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Novo documento</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="new-user-info">
                        <form>
                            <div class="row">
                            <div class="form-group col-md-6">
                                <label for="fname">First Name:</label>
                                <input type="text" class="form-control" id="fname" placeholder="First Name">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="lname">Last Name:</label>
                                <input type="text" class="form-control" id="lname" placeholder="Last Name">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="add1">Street Address 1:</label>
                                <input type="text" class="form-control" id="add1" placeholder="Street Address 1">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="add2">Street Address 2:</label>
                                <input type="text" class="form-control" id="add2" placeholder="Street Address 2">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="cname">Company Name:</label>
                                <input type="text" class="form-control" id="cname" placeholder="Company Name">
                            </div>
                            <div class="form-group col-sm-12">
                                <label>Country:</label>
                                <select class="form-control" id="selectcountry">
                                    <option>Select Country</option>
                                    <option>Caneda</option>
                                    <option>Noida</option>
                                    <option >USA</option>
                                    <option>India</option>
                                    <option>Africa</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="mobno">Mobile Number:</label>
                                <input type="text" class="form-control" id="mobno" placeholder="Mobile Number">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="altconno">Alternate Contact:</label>
                                <input type="text" class="form-control" id="altconno" placeholder="Alternate Contact">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="email">Email:</label>
                                <input type="email" class="form-control" id="email" placeholder="Email">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="pno">Pin Code:</label>
                                <input type="text" class="form-control" id="pno" placeholder="Pin Code">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="city">Town/City:</label>
                                <input type="text" class="form-control" id="city" placeholder="Town/City">
                            </div>
                            </div>
                            <hr>
                            <button type="submit" class="btn btn-primary">Adicionar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>
<script>
export default {
   name:'UserAdd'
}
</script>